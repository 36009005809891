import * as React from "react";
import {container, descriptionClass, imageClass} from "./BenefitCard.module.scss";
import clsx from "clsx";

const BenefitCard = ({image, title, description, className = ''}) => {
    return <div className={clsx(container, className)}>
        <div className={imageClass}>
            {image}
        </div>
        <h4>{title}</h4>
        <div className={descriptionClass}>{description}</div>
    </div>
}


export default BenefitCard;