import * as React from "react";
import GetStartedButton from "../../../components/getStartedButton/GetStartedButton";
import {descriptionText, font20} from "../../../assets/pageStyles/descriptionText.module.scss";
import {container, textContainer, imageContainer, popupImage, title, subtitle, introText, coverImage, imageSize, getStartedButton,top50,top47} from "./Intro.module.scss";
import {StaticImage} from "gatsby-plugin-image";
import clsx from "clsx";
import BenefitsText from "../../../components/benefitsText/BenefitsText";
import {useI18next, useTranslation} from "gatsby-plugin-react-i18next";

const Intro = ({sectionRef}) => {
    const {language}=useI18next();
    const {t}=useTranslation();

    return <section className={container} ref={sectionRef}>
        <div className={textContainer}>
            <h1 className={title}>{t('introSection.title')}</h1>
            <h2 className={subtitle}>{t('introSection.subtitle')}</h2>
            <p className={clsx(descriptionText, introText, font20)}>
                {t('introSection.paragraph')}
            </p>
            <GetStartedButton color className={getStartedButton} label={t('introSection.buttonLabel')}/>
            <BenefitsText whiteColor={false}/>
        </div>
        <div className={imageContainer}>
            <div className={popupImage}>
                <StaticImage
                    className={imageSize}
                    placeholder="tracedSVG"
                    loading="eager"
                    objectFit="contain"
                    alt="No training needed"
                    src={"../../../images/index/intro/no-training-needed.svg"}
                />
                <p className={clsx(language==='mne' ? top50 : top47)}>{t('introSection.subtitle')}</p>
            </div>

            <StaticImage
                loading="eager"
                placeholder="tracedSVG"
                className={coverImage}
                alt="No training needed"
                src={"../../../images/index/intro/Ilustracija-away.png"}
            />
        </div>
    </section>
}

export default Intro