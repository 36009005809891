import * as React from "react";
import {container} from "./Gains.module.scss";
import Dashboard from "./dashboard/Dashboard";
import Mobile from "./mobile/Mobile";
import Approval from "./approval/Approval";

const Gains = () => {
    return <section className={container}>
                <Dashboard/>
                <Mobile/>
                <Approval/>
            </section>
}

export default Gains