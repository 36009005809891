import * as React from "react";
import { navigate } from "gatsby";
import ButtonRounded from "../buttons/buttonRounded/ButtonRounded";
import {appConfig} from "../../assets/config";
import {useI18next} from "gatsby-plugin-react-i18next";

const GetStartedButton = ({color = false, className, label = "Get started"}) => {

    const {language} = useI18next();

    const signupRoute = appConfig.returnIframeRouteBillingPackages(language)
    const onGetStarted = (e) => {
        e.preventDefault()
        navigate(signupRoute)
    }
    return <ButtonRounded className={className} color={color} label={label} onClick={(e) => onGetStarted(e)}/>
}

export default GetStartedButton;