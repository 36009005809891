import * as React from "react";
import {descriptionText, blue, bold} from "../../../assets/pageStyles/descriptionText.module.scss";
import {container, textContainer, imageContainer, popupImage, title, subtitle, introText,  itemsContainer, coverImage, imageSize, subtitleMobile, subtitleDesktop} from "./Features.module.scss";
import {StaticImage} from "gatsby-plugin-image";
import clsx from "clsx";
import {useI18next, useTranslation} from "gatsby-plugin-react-i18next";

const Features = ({sectionRef}) => {
    const {language}=useI18next();
    const {t}=useTranslation();

    return <section className={container} id={"features"} ref={sectionRef}>
                <h2 className={clsx(title)}>{t('featuresSection.titleOne')} <span>{t('featuresSection.titleTwo')}</span></h2>
                <h2 className={clsx(subtitle, subtitleMobile)}>{t('featuresSection.sectionOne.titleOne')} <br/> {t('featuresSection.sectionOne.titleTwo')}</h2>
                <div className={itemsContainer}>
                    <div className={textContainer}>
                        <h3 className={clsx(subtitle, subtitleDesktop)}>{t('featuresSection.sectionOne.titleOne')} <br/> {t('featuresSection.sectionOne.titleTwo')}</h3>
                        <p className={clsx(descriptionText, introText)}>
                            {t('featuresSection.sectionOne.descriptionOne')} <span className={blue}>{t('featuresSection.sectionOne.descriptionTwo')}</span> {t('featuresSection.sectionOne.descriptionThree')} <span className={bold}>{t('featuresSection.sectionOne.descriptionFour')}</span> {t('featuresSection.sectionOne.descriptionFive')} <span className={bold}>{t('featuresSection.sectionOne.descriptionSix')}</span> {t('featuresSection.sectionOne.descriptionSeven')}
                        </p>
                    </div>
                    <div className={imageContainer}>
                        {language==='mne' ?
                            <StaticImage
                                className={coverImage}
                                placeholder="tracedSVG"
                                alt="away features"
                                src={"../../../images/index/features/calendar-mne.png"}
                            />
                            :
                            <StaticImage
                                className={coverImage}
                                placeholder="tracedSVG"
                                alt="away features"
                                src={"../../../images/index/features/Artboard–2@2x.png"}
                            />
                        }
                    </div>
                </div>
            </section>
}

export default Features