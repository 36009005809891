import * as React from "react"
import Header from "../components/Header";
import Layout from "../components/layout/Layout";
import Intro from "../pageComponents/index/intro/Intro";
import Teams from "../pageComponents/index/teams/Teams";
import Features from "../pageComponents/index/features/Features";
import Reports from "../pageComponents/index/reports/Reports";
import Gains from "../pageComponents/index/gains/Gains";
import TryItNow from "../components/tryItNow/TryItNow";
import Benefits from "../pageComponents/index/benefits/Benefits";
import Testimonials from "../pageComponents/index/testimonials/Testimonials";
import Footer from "../pageComponents/index/footer/Footer";
import {useRef} from "react";
import FAQ from "../pageComponents/index/faq/FAQ";
import {graphql} from "gatsby";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const IndexPage = (props) => {
    const introRef = useRef();
    const featuresRef = useRef();
    const benefitsRef = useRef();
  return (
      <Layout refs={{
          intro: introRef,
          benefits: benefitsRef,
          features: featuresRef,
      }} location={props?.location?.pathname}>
          <Intro sectionRef={introRef}/>
          <Benefits sectionRef={benefitsRef}/>
          <TryItNow/>
          <Features sectionRef={featuresRef}/>
          <Reports/>
          <Gains/>
          <Testimonials/>
          <Teams/>
          <TryItNow type={"try-now"}/>
          <FAQ/>
          <Footer/>
      </Layout>
  )
}

export default IndexPage

export const Head = () => <Header title={"away | Leave management system"}/>

