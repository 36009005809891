import * as React from "react";
import GetStartedButton from "../getStartedButton/GetStartedButton";
import BenefitsText from "../benefitsText/BenefitsText";
import clsx from "clsx";
import {section, container, textContainer, tryAwayTextContainer, textLeft, textCenter, title, tryAwayStyle, tryNowStyle, responsiveDisplay, spacingTop, backgroundImage, humanImage, benefits, image, imagesContainer, humanWithoutLaptop, humanWithLaptop} from "./TryItNow.module.scss"
import {StaticImage} from "gatsby-plugin-image";
import {sectionMargin} from "../../assets/pageStyles/sectionStyles.module.scss";
import {useTranslation} from "gatsby-plugin-react-i18next";

// types
// try-away
// try-now

const TryItNow = ({type = "try-away"}) => {
    const {t}=useTranslation();

    return <section className={clsx(section,  type === "try-now" && spacingTop)}>
        <div className={clsx(container, sectionMargin)}>
            <div className={clsx(type === "try-away" ? tryAwayTextContainer : textContainer, type === "try-away" ? textLeft : textCenter)}>
                <h3 className={clsx(title, type === "try-away" ? tryAwayStyle : tryNowStyle)}>{
                    type === "try-away"
                        ? <>{t('tryAwaySection.titleOne')} <br className={responsiveDisplay}/> {t('tryAwaySection.titleTwo')}</>
                        : <>{t('tryNowSection.titleOne')} <br className={responsiveDisplay}/> {t('tryNowSection.titleTwo')}</>
                }</h3>
                <GetStartedButton color={false} label={type === "try-away" ? t('tryAwaySection.buttonLabel') : t('tryNowSection.buttonLabel')}/>
                <BenefitsText className={benefits}/>
            </div>
            <div className={imagesContainer}>
                <div>
                <div className={backgroundImage}>
                <StaticImage
                    className={image}
                    objectFit="contain"
                    placeholder="tracedSVG"
                    alt="Try it now"
                    src={"../../images/index/tryAway/try-away-background.svg"}
                />
                </div>
                {type === "try-away" ?
                    <div className={clsx(humanImage, humanWithLaptop)}>
                    <StaticImage
                        className={image}
                        objectFit="contain"
                        placeholder="tracedSVG"
                        alt="Try it now"
                        src={"../../images/index/tryAway/try-away.png"}
                    />
                    </div>
                    :
                    <div className={clsx(humanImage, humanWithoutLaptop)}>
                    <StaticImage
                        className={image}
                        objectFit="contain"
                        placeholder="tracedSVG"
                        alt="Try it now"
                        src={"../../images/index/tryAway/try-now.png"}
                    />
                    </div>
                }
                </div>
            </div>
        </div>
    </section>
}

export default TryItNow;