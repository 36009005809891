import * as React from "react";
import {descriptionText, blue, bold, italic} from "../../../../assets/pageStyles/descriptionText.module.scss";
import {container, textContainer, imageContainer,subtitle, introText, itemsContainer, coverImage, subtitleMobile, subtitleDesktop} from "./Approval.module.scss";
import {StaticImage} from "gatsby-plugin-image";
import clsx from "clsx";
import {useI18next, useTranslation} from "gatsby-plugin-react-i18next";

const Approval = () => {
    const {language}=useI18next();
    const {t}=useTranslation();

    return <div className={container}>
                <h3 className={clsx(subtitle, subtitleMobile)}>{t('featuresSection.sectionFive.title')}</h3>
                <div className={itemsContainer}>
                    <div className={imageContainer}>
                        {language==='mne' ?
                            <StaticImage
                                className={coverImage}
                                placeholder="tracedSVG"
                                alt="Approval"
                                src={"../../../../images/index/gains/request-mne2.png"}
                            />
                            :
                            <StaticImage
                                className={coverImage}
                                placeholder="tracedSVG"
                                alt="Approval"
                                src={"../../../../images/index/gains/request-eng.png"}
                            />
                        }
                    </div>
                    <div className={textContainer}>
                        <h3 className={clsx(subtitle, subtitleDesktop)}>{t('featuresSection.sectionFive.title')}</h3>
                        <p className={clsx(descriptionText, introText)}>
                            {t('featuresSection.sectionFive.descriptionOne')} <span className={bold}>{t('featuresSection.sectionFive.descriptionTwo')}</span> {t('featuresSection.sectionFive.descriptionThree')} <span className={bold}>{t('featuresSection.sectionFive.descriptionFour')}</span> {t('featuresSection.sectionFive.descriptionFive')} <span className={bold}>{t('featuresSection.sectionFive.descriptionSix')}</span> {t('featuresSection.sectionFive.descriptionSeven')} <span className={bold}>{t('featuresSection.sectionFive.descriptionEight')}</span> {t('featuresSection.sectionFive.descriptionNine')} <span className={bold}>{t('featuresSection.sectionFive.descriptionTen')}</span> {t('featuresSection.sectionFive.descriptionEleven')}
                        </p>
                    </div>
                </div>
            </div>
}

export default Approval