import * as React from "react";
import {descriptionText, blue, bold} from "../../../assets/pageStyles/descriptionText.module.scss";
import {container, title, introText} from "./Reports.module.scss";
import clsx from "clsx";
import {useTranslation} from "gatsby-plugin-react-i18next";

const Reports = () => {
    const {t}=useTranslation();

    return <section className={container}>
                <h3 className={clsx(title)}>{t('featuresSection.sectionTwo.title')}</h3>
                  <p className={clsx(descriptionText, introText)}>
                      <span className={bold}>{t('featuresSection.sectionTwo.descriptionOne')}</span> {t('featuresSection.sectionTwo.descriptionTwo')} <span className={bold}>{t('featuresSection.sectionTwo.descriptionThree')}</span> {t('featuresSection.sectionTwo.descriptionFour')}
                  </p>
    </section>
}

export default Reports