import * as React from "react";
import {descriptionText, blue, bold} from "../../../../assets/pageStyles/descriptionText.module.scss";
import {container, textContainer, imageContainer,subtitle, introText, itemsContainer, coverImage, mobileApps, downloadImage} from "./Mobile.module.scss";
import {StaticImage} from "gatsby-plugin-image";
import clsx from "clsx";
import {useI18next, useTranslation} from "gatsby-plugin-react-i18next";

const Mobile = () => {
    const {language}=useI18next();
    const {t}=useTranslation();

    return <div className={container}>
                <div className={itemsContainer}>
                    <div className={textContainer}>
                        <h3 className={clsx(subtitle)}>{t('featuresSection.sectionFour.title')}</h3>
                        <p className={clsx(descriptionText, introText)}>
                            {t('featuresSection.sectionFour.descriptionOne')} <span className={bold}>{t('featuresSection.sectionFour.descriptionTwo')}</span> {t('featuresSection.sectionFour.descriptionThree')} <span className={bold}>{t('featuresSection.sectionFour.descriptionFour')}</span> {t('featuresSection.sectionFour.descriptionFive')} <span className={bold}>{t('featuresSection.sectionFour.descriptionSix')}</span> {t('featuresSection.sectionFour.descriptionSeven')} <span className={bold}>{t('featuresSection.sectionFour.descriptionEight')}</span> {t('featuresSection.sectionFour.descriptionNine')} <span className={bold}>{t('featuresSection.sectionFour.descriptionTen')}</span> {t('featuresSection.sectionFour.descriptionEleven')} <span className={bold}>{t('featuresSection.sectionFour.descriptionTwelve')}</span>
                        </p>
                        <div className={mobileApps}>
                            <a href="https://play.google.com/store/apps/details?id=llc.amplitudo.away" target={"_blank"}>
                            <StaticImage
                                className={downloadImage}
                                objectFit="contain"
                                placeholder="tracedSVG"
                                alt="Mobile application"
                                src={"../../../../images/index/gains/apps/google-play.svg"}
                            />
                            </a>
                            <a href="https://apps.apple.com/us/app/away-app/id1531016205" target={"_blank"}>
                            <StaticImage
                                className={downloadImage}
                                objectFit="contain"
                                placeholder="tracedSVG"
                                alt="Mobile application"
                                src={"../../../../images/index/gains/apps/app-store.svg"}
                            />
                            </a>
                        </div>
                    </div>
                    <div className={imageContainer}>
                        {language==='mne' ?
                            <StaticImage
                                className={coverImage}
                                placeholder="tracedSVG"
                                alt="Mobile application"
                                src={"../../../../images/index/gains/mobile-mne2.png"}
                            />
                            :
                            <StaticImage
                                className={coverImage}
                                placeholder="tracedSVG"
                                alt="Mobile application"
                                src={"../../../../images/index/gains/mobile-eng2.png"}
                            />
                        }
                    </div>
                </div>
            </div>
}

export default Mobile