import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    sectionClass,
    introductoryText,
    card,
    userImageContainer,
    userImage,
    textContainer,
    testimonialContainer,
    testimonial,
    cardBottom,
    personData,
    buttonsContainer,
    previousBtn,
    nextBtn,
    imageContainer,
    mobileView
} from "./Testimonials.module.scss";
import clsx from "clsx";
import {useRef} from "react";
import {useTranslation} from "gatsby-plugin-react-i18next";

const Testimonials = () => {
    const {t}=useTranslation();

    const hasMoreTestimonials = false;
    const sliderRef = useRef();
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        draggable: false
    }

    return    <section className={sectionClass}>
                    <h3>{t('testimonials.title')}</h3>
                 <p className={introductoryText}>{t('testimonials.subtitle')}</p>
                <div className={card}>
                    <Slider ref={sliderRef} {...settings} >
                    {/*   testimonial */}
                    <div className={testimonialContainer}>
                    <div className={imageContainer}>
                        <div className={userImageContainer}>
                            <StaticImage objectFit="cover"
                                         placeholder="tracedSVG"
                                         className={userImage}
                                         src={"../../../images/index/testimonials/jasna.png"}
                                         alt="Testimonial person image"/>
                        </div>
                        <div className={clsx(personData, mobileView)}>
                            <div>Jasna Pejović</div>
                            <div>CEO of DigitalBee</div>
                        </div>
                    </div>
                    <div className={textContainer}>
                        <StaticImage
                            placeholder="tracedSVG"
                            src={"../../../images/index/testimonials/quotes.svg"}
                            alt="Testimonial"/>
                        <div className={testimonial}>
                            {t('testimonials.testimonialOne')}
                        </div>
                        <div className={cardBottom}>
                            <div className={personData}>
                                <div>Jasna Pejović</div>
                                <div>CEO of DigitalBee</div>
                            </div>
                        </div>
                    </div>
                    </div>
                    {/*  end of testimonial  */}
                    {/*   testimonial */}
                    {/*<div className={testimonialContainer}>*/}
                    {/*<div className={imageContainer}>*/}
                    {/*    <div className={userImageContainer}>*/}
                    {/*        <StaticImage objectFit="contain"*/}
                    {/*                     placeholder="tracedSVG"*/}
                    {/*                     className={userImage}*/}
                    {/*                     src={"../../../images/index/testimonials/privredna_komora.png"}*/}
                    {/*                     alt="Testimonial person image"/>*/}
                    {/*    </div>*/}
                    {/*    <div className={clsx(personData, mobileView)}>*/}
                    {/*        <div>PKCG</div>*/}
                    {/*        <div></div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className={textContainer}>*/}
                    {/*    <StaticImage*/}
                    {/*        placeholder="tracedSVG"*/}
                    {/*        src={"../../../images/index/testimonials/quotes.svg"}*/}
                    {/*        alt="Testimonial"/>*/}
                    {/*    <div className={testimonial}>*/}
                    {/*        We're happy to have found a solution to the long approval process. Using Away, our staff can request time off through a mobile app, and supervisors can quickly approve requests and generate reports in seconds.*/}
                    {/*    </div>*/}
                    {/*    <div className={cardBottom}>*/}
                    {/*        <div className={personData}>*/}
                    {/*            <div>PKCG</div>*/}
                    {/*            <div></div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*  end of testimonial  */}
                    </Slider>
                    {hasMoreTestimonials &&
                    <div className={buttonsContainer}>
                        <button className={previousBtn} onClick={() => sliderRef.current.slickPrev()} aria-label="Previous"></button>
                        <button className={nextBtn} onClick={() => sliderRef.current.slickNext()} aria-label="Next"></button>
                    </div>
                    }
                </div>

                </section>
}

export default Testimonials;