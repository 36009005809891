// extracted by mini-css-extract-plugin
export var container = "Intro-module--container--MYLB3";
export var coverImage = "Intro-module--cover-image--M6Z5Y";
export var getStartedButton = "Intro-module--getStartedButton--om+ZH";
export var imageContainer = "Intro-module--image-container--tFm6A";
export var imageSize = "Intro-module--imageSize--HIkUv";
export var introText = "Intro-module--intro-text--L0JzA";
export var popupImage = "Intro-module--popupImage--Yy0jf";
export var subtitle = "Intro-module--subtitle--QVFwA";
export var textContainer = "Intro-module--text-container--bkznJ";
export var title = "Intro-module--title--rxKgo";
export var top47 = "Intro-module--top-47--lpf6m";
export var top50 = "Intro-module--top-50--Zd4A4";