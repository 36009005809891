import * as React from "react";
import {container, title, linkImage, linkContainer, sliderContainer} from "./Teams.module.scss";
import {StaticImage} from "gatsby-plugin-image";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useWindowDimensions from "../../../hooks/useWindowDimensions/useWindowDimensions";
import {sectionMargin} from "../../../assets/pageStyles/sectionStyles.module.scss";
import clsx from "clsx";
import {useTranslation} from "gatsby-plugin-react-i18next";


const Teams = () => {
    const {t}=useTranslation();
    const {width} = useWindowDimensions();

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: width < 800
                            ? 2
                            : width < 1000
                                ? 3
                                : width < 1300
                                    ? 4
                                    : 5,
    }

    return <section>
                <div className={clsx(container, sectionMargin)}>
                        <h2 className={title}>{t('teamsSection.titleOne')} <span>{t('teamsSection.titleTwo')}</span></h2>
                        <div>
                            <Slider {...settings} className={sliderContainer}>

                                <a href={"https://www.addiko.me/"} target="_blank" rel="noreferrer" className={linkContainer}>
                                    <StaticImage
                                        key={`teams-slider-${0}`}
                                        className={linkImage}
                                        objectFit="contain"
                                        placeholder="tracedSVG"
                                        alt={"Addiko bank"}
                                        src={"../../../images/index/teams/addiko.png"}
                                    />
                                </a>
                                <a href={"https://regagen.co.me/"} target="_blank" rel="noreferrer" className={linkContainer}>
                                    <StaticImage
                                        key={`teams-slider-${1}`}
                                        className={linkImage}
                                        objectFit="contain"
                                        placeholder="tracedSVG"
                                        alt={"Regagen"}
                                        src={"../../../images/index/teams/regagen.png"}
                                    />
                                </a>
                                <a href={"https://finveoequities.com/"} target="_blank" rel="noreferrer" className={linkContainer}>
                                    <StaticImage
                                        key={`teams-slider-${2}`}
                                        className={linkImage}
                                        objectFit="contain"
                                        placeholder="tracedSVG"
                                        alt={"Finveo equities"}
                                        src={"../../../images/index/teams/finveo.png"}
                                    />
                                </a>
                                <a href={"https://www.lovcenbanka.me/en/"} target="_blank" rel="noreferrer" className={linkContainer}>
                                    <StaticImage
                                        key={`teams-slider-${3}`}
                                        className={linkImage}
                                        objectFit="contain"
                                        placeholder="tracedSVG"
                                        alt={"Lovcen bank"}
                                        src={"../../../images/index/teams/lovcen.png"}
                                    />
                                </a>
                                <a href={"https://amplitudo.me/"} target="_blank" rel="noreferrer" className={linkContainer}>
                                    <StaticImage
                                        key={`teams-slider-${4}`}
                                        className={linkImage}
                                        objectFit="contain"
                                        placeholder="tracedSVG"
                                        alt={"Amplitudo"}
                                        src={"../../../images/index/teams/amplitudo.png"}
                                    />
                                </a>

                            </Slider>
                        </div>
                </div>
    </section>
}

export default Teams