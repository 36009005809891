import * as React from "react";
import {container, subtitle, itemsContainer, questionsContainer, answersContainer, answer, title, content, active} from "./FAQ.module.scss";

import clsx from "clsx";
import {sectionMargin} from "../../../assets/pageStyles/sectionStyles.module.scss";
import {useTranslation} from "gatsby-plugin-react-i18next";

const FAQ = ({sectionRef}) => {
    const [questionOpenedIndex, setQuestionOpenedIndex] = React.useState(0);
    const {t}=useTranslation();

    const handleSelection = (e, index) => {
        if(e?.keyCode === 9){
            setQuestionOpenedIndex(index)
        }
    }

    return <section className={container} id={"benefits"} ref={sectionRef}>
                <h3 className={clsx(subtitle)}>{t('faqSection.titleOne')} <span>{t('faqSection.titleTwo')}</span></h3>
                <div className={clsx(itemsContainer, sectionMargin)}>
                    <ul className={clsx(questionsContainer)}>
                        <li tabIndex="0" className={clsx(questionOpenedIndex === 0 && active)} onClick={() => setQuestionOpenedIndex(0)} onKeyDown={(e) => handleSelection(e, 0)}>{t('faqSection.questionOne.question')}</li>
                        <li tabIndex="0" className={clsx(questionOpenedIndex === 1 && active)} onClick={() => setQuestionOpenedIndex(1)} onKeyDown={(e) => handleSelection(e, 1)}>{t('faqSection.questionTwo.question')}</li>
                        <li tabIndex="0" className={clsx(questionOpenedIndex === 2 && active)} onClick={() => setQuestionOpenedIndex(2)} onKeyDown={(e) => handleSelection(e, 2)}>{t('faqSection.questionThree.question')}</li>
                        <li tabIndex="0" className={clsx(questionOpenedIndex === 3 && active)} onClick={() => setQuestionOpenedIndex(3)} onKeyDown={(e) => handleSelection(e, 3)}>{t('faqSection.questionFour.question')}</li>
                        <li tabIndex="0" className={clsx(questionOpenedIndex === 4 && active)} onClick={() => setQuestionOpenedIndex(4)} onKeyDown={(e) => handleSelection(e, 4)}>{t('faqSection.questionFive.question')}</li>

                    </ul>
                    <div className={clsx(answersContainer)}>
                        <div className={clsx(answer, questionOpenedIndex === 0 && active)}>
                            <div className={title} onClick={() => setQuestionOpenedIndex(0)}>{t('faqSection.questionOne.question')}</div>
                            <div className={content}>{t('faqSection.questionOne.answer')}</div>
                        </div>
                            <div className={clsx(answer, questionOpenedIndex === 1 && active)}>
                                <div className={title} onClick={() => setQuestionOpenedIndex(1)}>{t('faqSection.questionTwo.question')}</div>
                                <div className={content}>{t('faqSection.questionTwo.answer')}</div>
                            </div>
                            <div className={clsx(answer, questionOpenedIndex === 2 && active)}>
                                <div className={title} onClick={() => setQuestionOpenedIndex(2)}>{t('faqSection.questionThree.question')}</div>
                                <div className={content}>{t('faqSection.questionThree.answer')}</div>
                            </div>
                            <div className={clsx(answer, questionOpenedIndex === 3 && active)}>
                                <div className={title} onClick={() => setQuestionOpenedIndex(3)}>{t('faqSection.questionFour.question')}</div>
                                <div className={content}>{t('faqSection.questionFour.answer')}</div>
                            </div>
                            <div className={clsx(answer, questionOpenedIndex === 4 && active)}>
                                <div className={title} onClick={() => setQuestionOpenedIndex(4)}>{t('faqSection.questionFive.question')}</div>
                                <div className={content}>{t('faqSection.questionFive.answer')}</div>
                            </div>
                    </div>
                </div>
            </section>
}

export default FAQ