// extracted by mini-css-extract-plugin
export var container = "Features-module--container--X4Zjo";
export var coverImage = "Features-module--cover-image--BZIr9";
export var imageContainer = "Features-module--image-container--ab4Vo";
export var imageSize = "Features-module--imageSize--E6wmX";
export var introText = "Features-module--intro-text--aK7vQ";
export var itemsContainer = "Features-module--items-container--9QZy2";
export var popupImage = "Features-module--popupImage--+cFh8";
export var subtitle = "Features-module--subtitle--Ym2GL";
export var subtitleDesktop = "Features-module--subtitle-desktop--4Wsl7";
export var subtitleMobile = "Features-module--subtitle-mobile--oUWON";
export var textContainer = "Features-module--text-container---wXo3";
export var title = "Features-module--title--ddlzx";