// extracted by mini-css-extract-plugin
export var buttonsContainer = "Testimonials-module--buttons-container--UO+pP";
export var card = "Testimonials-module--card--lXQ9q";
export var cardBottom = "Testimonials-module--card-bottom--0L-P9";
export var imageContainer = "Testimonials-module--image-container--8v9+R";
export var introductoryText = "Testimonials-module--introductory-text--m7-Xp";
export var mobileView = "Testimonials-module--mobile-view--FlJGQ";
export var nextBtn = "Testimonials-module--next-btn--W1Aw5";
export var personData = "Testimonials-module--person-data--Y3LNY";
export var previousBtn = "Testimonials-module--previous-btn--hCIyS";
export var sectionClass = "Testimonials-module--section-class--MtJp4";
export var testimonial = "Testimonials-module--testimonial--Qw24k";
export var testimonialContainer = "Testimonials-module--testimonial-container--l1DqX";
export var textContainer = "Testimonials-module--text-container--r1h9q";
export var userImage = "Testimonials-module--user-image--+Qf+N";
export var userImageContainer = "Testimonials-module--user-image-container--YuKUI";