// extracted by mini-css-extract-plugin
export var benefits = "Gains-module--benefits--XLgta";
export var blue = "Gains-module--blue--0nx6l";
export var container = "Gains-module--container--vQTCU";
export var coverImage = "Gains-module--cover-image--6NapO";
export var getStartedButton = "Gains-module--getStartedButton--TvbDB";
export var imageContainer = "Gains-module--image-container--w9EiW";
export var imageSize = "Gains-module--imageSize--84-re";
export var introText = "Gains-module--intro-text--0T0X0";
export var popupImage = "Gains-module--popupImage--0R-p9";
export var textContainer = "Gains-module--text-container--UKoE-";
export var title = "Gains-module--title--F3VK3";