import * as React from "react";
import {benefits, black, white} from "./BenefitsText.module.scss";
import clsx from "clsx";
import {useTranslation} from "gatsby-plugin-react-i18next";

const BenefitsText = ({whiteColor = true, className}) => {
    const {t}=useTranslation();

    return  <div className={clsx(benefits, whiteColor ? white : black, className)}><span>{t('common.noCreditCard')}</span><span>{t('common.trial')}</span><span>{t('common.cancellation')}</span></div>
}

export default BenefitsText;