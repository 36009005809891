import * as React from "react";
import {container, subtitle, itemsContainer, item} from "./Benefits.module.scss";

import clsx from "clsx";
import BenefitCard from "../../../components/benefitCard/BenefitCard";
import {StaticImage} from "gatsby-plugin-image";
import {sectionMargin} from "../../../assets/pageStyles/sectionStyles.module.scss";
import {useTranslation} from "gatsby-plugin-react-i18next";

const Benefits = ({sectionRef}) => {
    const {t}=useTranslation();

    return <section className={container} id={"benefits"} ref={sectionRef}>
                <h3 className={clsx(subtitle)}>{t('benefitsSection.title')}</h3>
                <div className={clsx(itemsContainer, sectionMargin)}>
                    <BenefitCard className={item}
                                 image={<StaticImage src="../../../images/index/benefits/no-overlapping.svg" alt={"No overlapping"}
                                                     placeholder="tracedSVG" />}
                                 title={t('benefitsSection.cards.cardOne.title')}
                                 description={<>{t('benefitsSection.cards.cardOne.descriptionOne')} <span>{t('benefitsSection.cards.cardOne.descriptionTwo')}</span> {t('benefitsSection.cards.cardOne.descriptionThree')} <span>{t('benefitsSection.cards.cardOne.descriptionFour')}</span> {t('benefitsSection.cards.cardOne.descriptionFive')}
                                 </>}
                    />
                    <BenefitCard className={item}
                                 image={<StaticImage src="../../../images/index/benefits/precision.svg" alt={"Precision"}
                                                     placeholder="tracedSVG" />}
                                 title={t('benefitsSection.cards.cardTwo.title')}
                                 description={<>{t('benefitsSection.cards.cardTwo.descriptionOne')} <span>{t('benefitsSection.cards.cardTwo.descriptionTwo')}</span> {t('benefitsSection.cards.cardTwo.descriptionThree')} <span>{t('benefitsSection.cards.cardTwo.descriptionFour')}</span> {t('benefitsSection.cards.cardTwo.descriptionFive')}
</>}
                    />
                    <BenefitCard className={item}
                                 image={<StaticImage src="../../../images/index/benefits/accessibility.svg" alt={"Accessibility"}
                                                     placeholder="tracedSVG" />}
                                 title={t('benefitsSection.cards.cardThree.title')}
                                 description={<>{t('benefitsSection.cards.cardThree.descriptionOne')} <span>{t('benefitsSection.cards.cardThree.descriptionTwo')}</span> {t('benefitsSection.cards.cardThree.descriptionThree')}
                                 </>}
                    />
                    <BenefitCard className={item}
                                 image={<StaticImage src="../../../images/index/benefits/employee-satisfaction.svg" alt={"Employee satisfaction"}
                                                     placeholder="tracedSVG" />}
                                 title={t('benefitsSection.cards.cardFour.title')}
                                 description={<><span>{t('benefitsSection.cards.cardFour.descriptionOne')}</span> {t('benefitsSection.cards.cardFour.descriptionTwo')} <span>{t('benefitsSection.cards.cardFour.descriptionThree')}</span> {t('benefitsSection.cards.cardFour.descriptionFour')}
                                 </>}
                    />
                    <BenefitCard className={item}
                                 image={<StaticImage src="../../../images/index/benefits/time-saving.svg" alt={"Time-saving"}
                                                     placeholder="tracedSVG" />}
                                 title={t('benefitsSection.cards.cardFive.title')}
                                 description={<>{t('benefitsSection.cards.cardFive.descriptionOne')} <span>{t('benefitsSection.cards.cardFive.descriptionTwo')}</span> {t('benefitsSection.cards.cardFive.descriptionThree')}</>}
                    />
                    <BenefitCard className={item}
                                 image={<StaticImage src="../../../images/index/benefits/increased-productivity.svg" alt={"Increased productivity"}
                                                     placeholder="tracedSVG" />}
                                 title={t('benefitsSection.cards.cardSix.title')}
                                 description={<>{t('benefitsSection.cards.cardSix.descriptionOne')} <span>{t('benefitsSection.cards.cardSix.descriptionTwo')}</span> {t('benefitsSection.cards.cardSix.descriptionThree')}</>}
                    />
                </div>
            </section>
}

export default Benefits