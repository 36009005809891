import React from "react";
import {away_email, away_phone, company_link, company_name} from "../../../config/config";
import {container, contactData, privacyPolicy, termsOfUse, privacyContainer, separator, rights} from "./Footer.module.scss";
import {sectionMargin} from "../../../assets/pageStyles/sectionStyles.module.scss";
import Privacy from "../../../assets/documents/privacy.pdf";
import Terms from "../../../assets/documents/terms.pdf";
import {useTranslation} from "gatsby-plugin-react-i18next";

const Footer = () => {
    const {t}=useTranslation();

    const current = new Date();
    return <section className={container}>
        <div className={sectionMargin}>
            <div className={rights}>
                © {t('footer.copy')} {current.getFullYear()} <a href={company_link}>{company_name}</a>
            </div>
            <div className={privacyContainer}>
                <div><a href={Privacy}
                        download="Privacy Policy" className={privacyPolicy}>
                    {t('footer.privacyPolicy')}
                </a>
                </div>
                <div className={separator}>|</div>
                <div><a href={Terms}
                        download="Terms of Use" className={termsOfUse}>
                    {t('footer.termsOfUse')}
                </a></div>
            </div>
            <div className={contactData}>
                <a href={`mailto:${away_email}`}>{away_email}</a>
                <a href={`tel:${away_phone?.split(" ")?.join("")}`}>{away_phone}</a>
            </div>
        </div>
    </section>
}

export default Footer;