import React, {useEffect, useState} from "react";

function getWindowDimensions() {
	if (typeof window === `undefined`) {
		return {
			width: null,
			height: null,
		};
	}else{

		const {clientWidth: width, clientHeight: height} = document?.documentElement;
		return {
			width,
			height,
		};
	}
}

export default function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	);

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);
	return windowDimensions;
}
