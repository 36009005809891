import * as React from "react";
import {descriptionText, blue, bold} from "../../../../assets/pageStyles/descriptionText.module.scss";
import {container, textContainer, imageContainer,subtitle, introText, itemsContainer, coverImage, subtitleMobile, subtitleDesktop} from "./Dashboard.module.scss";
import {StaticImage} from "gatsby-plugin-image";
import clsx from "clsx";
import {useI18next, useTranslation} from "gatsby-plugin-react-i18next";

const Dashboard = () => {
    const {language}=useI18next();
    const {t}=useTranslation();

    return <div className={container}>
                <h3 className={clsx(subtitle, subtitleMobile)}>{t('featuresSection.sectionThree.titleOne')} <br/> {t('featuresSection.sectionThree.titleTwo')}</h3>
                <div className={itemsContainer}>
                    <div className={imageContainer}>
                        {language==='mne' ?
                            <StaticImage
                                className={coverImage}
                                placeholder="tracedSVG"
                                alt="Dashboard"
                                src={"../../../../images/index/gains/dashboard-mne.png"}
                            />
                            :
                            <StaticImage
                                className={coverImage}
                                placeholder="tracedSVG"
                                alt="Dashboard"
                                src={"../../../../images/index/gains/dashboard.png"}
                            />
                        }
                    </div>
                    <div className={textContainer}>
                        <h3 className={clsx(subtitle, subtitleDesktop)}>{t('featuresSection.sectionThree.titleOne')} <br/> {t('featuresSection.sectionThree.titleTwo')}</h3>
                        <p className={clsx(descriptionText, introText)}>
                            {t('featuresSection.sectionThree.descriptionOne')} <span className={bold}>{t('featuresSection.sectionThree.descriptionTwo')}</span> {t('featuresSection.sectionThree.descriptionThree')} <span className={bold}>{t('featuresSection.sectionThree.descriptionFour')}</span> {t('featuresSection.sectionThree.descriptionFive')}
                        </p>
                    </div>
                </div>
            </div>
}

export default Dashboard