// extracted by mini-css-extract-plugin
export var backgroundImage = "TryItNow-module--background-image--Sfn1T";
export var benefits = "TryItNow-module--benefits--Cnwdg";
export var container = "TryItNow-module--container--8AxgD";
export var humanImage = "TryItNow-module--human-image--7oxS8";
export var humanWithLaptop = "TryItNow-module--human-with-laptop--0qBZY";
export var humanWithoutLaptop = "TryItNow-module--human-without-laptop--X+T09";
export var image = "TryItNow-module--image--nmMk-";
export var imagesContainer = "TryItNow-module--images-container--rPTV5";
export var responsiveDisplay = "TryItNow-module--responsive-display--zN2FN";
export var section = "TryItNow-module--section--hR9wy";
export var spacingTop = "TryItNow-module--spacing-top--VDymb";
export var textCenter = "TryItNow-module--text-center--jRvFz";
export var textContainer = "TryItNow-module--text-container--mJMMG";
export var textLeft = "TryItNow-module--text-left--vOmrr";
export var title = "TryItNow-module--title--oH46f";
export var tryAwayStyle = "TryItNow-module--try-away-style--q5NBa";
export var tryAwayTextContainer = "TryItNow-module--try-away-text-container--IOpDd";
export var tryNowStyle = "TryItNow-module--try-now-style--qOBNq";